<template>
  <div class="px-5" id="page-shop_items">

    <SplitInfoPopup
      ref="split-info-popup"
      :title="$t('INVOICE.JOB_RESULT')"
      :bad_text="job_result_bad_text"
      :good_text="job_result_good_text"
    />

    <InvoiceEmailSendoutModal
      ref="email-sendout-modal"
      :options="sendout_options"
      :count="count"
      @confirm="on_email_sendout_confirm"
    />

    <InvoicePDFOptionsModal
      ref="invoice-pdf-modal"
      :options="pdf_options"
      :count="count"
      @confirm="on_invoice_pdf_confirmed"
    />

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('INVOICE.CONFIRM_DELETE')"
      ref="deleteInvoiceConfirm"
      type="success"
      @confirm="onDeleteInvoiceConfirm"
    />
    <h5 class="mb-6 ml-2">{{ $t('INVOICE.INVOICING') }}</h5>

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{$t('INVOICE.TAB_INVOICES')}}</v-tab>
        <v-tab @click.prevent="refresh_settings">{{$t('INVOICE.TAB_BGMAX')}}</v-tab>
        <v-tab>{{$t('INVOICE.TAB_BGMAX_ERRORS')}} ({{ bgmax_errors.length }})</v-tab>
        <!--<v-tab>{{$t('INVOICE.TAB_COMPANIES')}}</v-tab>-->
        <v-tab>{{$t('INVOICE.TAB_TEMPLATES')}}</v-tab>
        <v-tab>{{$t('INVOICE.TAB_SETTINGS')}}</v-tab>

        <v-tab-item>

          <InvoiceTable
            class="mt-4"
            ref="invoices-table"
            @filters_changed="filters_changed"
            @email_sendout_clicked="email_sendout_clicked"
            @create_pdf_clicked="on_create_pdf_clicked"
            @download_invoice_clicked="download_invoice_clicked"
            @print_invoice_clicked="print_invoice_clicked"
            @delete_invoice_clicked="delete_invoice_clicked"
            @submit_update="submit_update"
          />
        </v-tab-item>
        <v-tab-item>
          <input hidden type="file" id="file" ref="file" v-on:change="handleFileUpload()" />
          <!-- <div class="card-toolbar ">
                <a
                  href="#"
                  class="btn btn-primary font-weight-bolder font-size-sm"
                  @click="$refs.file.click()"
                  ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny BgMax-fil</a
                >
              </div> -->
          <BgMaxTable
            ref="bgmax-table"
            class="mt-4"
            :items="bgMaxUploads"
            @create_file_clicked="create_file_clicked"

          />
        </v-tab-item>


        <v-tab-item>
          <!-- bgmax errors -->
          <BgMaxErrorTable
            :items="bgmax_errors"
            @filters_changed="bgmax_error_filters_changed"
            @on_bgmax_matched="on_bgmax_matched"
            @deleted="bgmax_error_deleted"
          />
        </v-tab-item>

        <!--<v-tab-item>
          <InvoiceCompaniesTab class="mt-4"/>
        </v-tab-item>-->
        <v-tab-item>
          <InvoiceTemplateTab class="mt-4"/>
        </v-tab-item>
        <v-tab-item>
          <InvoiceSettingsTab 
            @updated="updated_company_settings" class="mt-4"/>
        </v-tab-item>
      </v-tabs>
    </b-card>
    <div class="scroll-to-container"></div>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import InvoiceTable from '@/view/pages/ml/invoices/InvoiceTable.vue';
import BgMaxTable from '@/view/pages/ml/invoices/BgMaxTable.vue';
import BgMaxErrorTable from '@/view/pages/ml/invoices/BgMaxErrorTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import SplitInfoPopup from '@/view/components/SplitInfoPopup.vue';
import InvoiceSettingsTab from '@/view/pages/ml/invoices/InvoiceSettingsTab.vue';
import InvoiceCompaniesTab from '@/view/pages/ml/invoices/InvoiceCompaniesTab.vue';
import InvoiceTemplateTab from '@/view/pages/ml/invoices/InvoiceTemplateTab.vue';
import InvoiceEmailSendoutModal from '@/view/components/InvoiceEmailSendoutModal.vue';
import InvoicePDFOptionsModal from '@/view/components/InvoicePDFOptionsModal.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'invoices',
  mixins: [ toasts ],
  components: {
    InvoiceTable,
    BgMaxTable,
    BgMaxErrorTable,
    Confirm,
    InvoiceCompaniesTab,
    InvoiceTemplateTab,
    InvoiceEmailSendoutModal,
    InvoicePDFOptionsModal,
    InvoiceSettingsTab,
    SplitInfoPopup
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadBgMax();
    this.loadData();

  },
  data() {
    return {
      count: 0,
      original_bgmax_errors: [],
      bgmax_errors: [],
      job_result_good_text: '',
      job_result_bad_text: '',
      created_from: null,
      created_to: null,
      selectedInvoices: [],
      selectedPaymentStatus: null,
      printed: null,
      filteredItems: null,
      isAllInvoices: false,
      filters: {},
      files: [],
      bgMaxUploads: [],
      toDelete: null,
      sendout_options: {},
      pdf_options: {}
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
      }
    }
  },
  methods: {
    refresh_settings() {

      
      this.$nextTick(()=>{
        /// stupid workaround for tab bug
        setTimeout(()=>{
          this.$refs['bgmax-table'].refresh_settings()
        }, 500);
        
      });
      
    },
    updated_company_settings(data) {

    },
    bgmax_error_deleted(bgmax_error) {
      this.bgmax_errors = this.bgmax_errors.filter(item => item.id !== bgmax_error.id);
    },
    on_bgmax_matched(bgmax) {
      const index = this.bgmax_errors.findIndex(item => item.id === bgmax.id);

      if (index >= 0) {
        this.bgmax_errors[index] = bgmax;
        this.original_bgmax_errors = this.bgmax_errors;

        this.bgmax_errors = [ ...this.bgmax_errors ];
      }
    },

    bgmax_error_filters_changed(filters) {
      this.bgmax_errors = this.original_bgmax_errors;


      if (filters.created_from) {
        this.bgmax_errors = this.bgmax_errors.filter(item => dayjs(item.created_at).format('YYYY-MM-DD') >= filters.created_from);
      }

      if (filters.created_to) {
        this.bgmax_errors = this.bgmax_errors.filter(item => dayjs(item.created_at).format('YYYY-MM-DD') <= filters.created_to);
      }

      if (filters.text && filters.text !== '') {
        this.bgmax_errors = this.bgmax_errors.filter(item => (item.ocr+'').indexOf(filters.text) !== -1);
      }
    },

    filters_changed(filters) {

      this.selectedPaymentStatus = filters.payment_status;
      this.created_from = filters.created_from;
      this.created_to = filters.created_to;
      this.printed = filters.printed;
    },
    downloadExportReportClicked() {
      axios
        .post(`/invoice/export_report`, { period_id: this.currentPeriodId })
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
            this.toastr('success', this.$t('COMMON.OK'), 'Rapport skapad');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Ett fel uppstod');
          }
        })
        .catch(err => {
          console.error('downloadExportReportClicked', err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa rapport');
        });
    },
    email_sendout_clicked(options, count) {
      
      this.sendout_options.created_from = options.created_from;
      this.sendout_options.created_to = options.created_to;
      this.sendout_options.payment_status = options.payment_status;
      this.sendout_options.printed = options.printed;

      this.sendout_options = { ...this.sendout_options };

      this.count = count;
      
      this.$refs['email-sendout-modal'].show();
    },

    async post_invoice_emails(options, email_template_id, invoice_template_id, due_date) {
      try {
        const response = await axios.post(`/invoice/emails/options`, {
          options,
          email_template_id:    email_template_id,
          invoice_template_id:  invoice_template_id,
          due_date:             due_date
        });

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },

    async on_email_sendout_confirm(options) {

      const loader = this.$loading.show();

      try {
        const response = await this.post_invoice_emails(
          this.sendout_options,
          options.email_template_id,
          options.invoice_template_id,
          options.due_date
        );


        if (response.missing_emails.length > 0) {
          this.job_result_bad_text = this.$t('INVOICE.JOB_RESULT_BAD_TEXT', { num: response.missing_emails.length});
        }
        else {
          this.job_result_bad_text = '';
        }

        this.job_result_good_text = this.$t('INVOICE.JOB_RESULT_GOOD_TEXT', { num: response.valid_emails.length});

        this.$refs['split-info-popup'].show();

        loader & loader.hide();
        this.toastr('success', this.$t('COMMON.OK'), 'Körning ' + response.job.id + ' skapad');
      }
      catch (err) {
        console.error('on_email_sendout_confirm', err);

        loader & loader.hide();
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa körning');
      }

    },
    on_create_pdf_clicked(options, count) {

      this.pdf_options.created_from = options.created_from;
      this.pdf_options.created_to = options.created_to;
      this.pdf_options.payment_status = options.payment_status;
      this.pdf_options.printed = options.printed;

      this.pdf_options = { ...this.pdf_options };

      this.count = count;
      
      this.$refs['invoice-pdf-modal'].show();
    },

    on_invoice_pdf_confirmed(options) {

      const loader = this.$loading.show();
      this.postStitchInvoices(
        'FILTER',
        [],
        options.payment_status,
        options.created_from,
        options.created_to,
        options.printed,
        options.template_id,
        options.order_by
        )
      .then(job => {
        loader & loader.hide();
        this.toastr('success', this.$t('COMMON.OK'), 'Körning ' + job.id + ' skapad');
      })
      .catch(err => {
        loader & loader.hide();
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa körning');
        console.error('onConfirmPDFOptions', err);
      });
    },

    onCancelInvoiceOptions() {
    },

    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.loadFiles();
    },
    create_file_clicked() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.uploadBgMax();
    },

    async load_bgmax_errors() {
      try {
        const result = await axios.get(`/invoice/bgmax/errors`);

        if (result.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_BGMAX_ERROR_GET'));
          return false;
        }

        this.original_bgmax_errors = result.data;
        this.bgmax_errors = result.data;
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_BGMAX_ERROR_GET'));
        return false;
      }

      return true;
    },

    loadBgMax() {
      axios
        .get(`/invoice/bgmax?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.bgMaxUploads = res.data;
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta BgMax-uppladdningar');
          }
        })
        .catch(error => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta BgMax-uppladdningar');
        });

      this.load_bgmax_errors();
    },
    loadFiles(show_file_id = undefined) {
      this.files = [];
      axios
        .get(
          `/file?company_id=${this.currentCompanyId}&period_id=${this.currentPeriodId}&file_type=FILE_BGMAX`
        )
        .then(res => {
          if (res.status === 200) {
            this.files = res.data;

            if (show_file_id !== undefined) {
              const f = this.files.find(item => item.file_id === show_file_id);

              if (f !== undefined) {
                this.form = f;
              }
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista filer');
        });
    },

    downloadFile(file_id) {
      axios
        .get(`/file/${file_id}`)
        .then(res => {
          if (res.status === 200) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        });
    },
    scrollToEditInvoice() {

      this.$nextTick(() => {
        const el = that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      });

    },
    print_invoice_clicked(invoice_id) {
      axios
        .put(`/invoice/${invoice_id}`, { printed: true })
        .then(res => {

          this.toastr('success', this.$t('COMMON.OK'), 'Fakturan har satts som utskriven');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        });
    },
    download_invoice_clicked(invoice_id) {
      axios
        .get(`/invoice/file/${invoice_id}`)
        .then(res => {
          if (res.status === 200) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          }
          else {
            console.error('invalid status ' + res.status);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
        });
    },
    delete_invoice_clicked(invoice_id) {
      this.toDelete = invoice_id;
      this.$refs['deleteInvoiceConfirm'].show();
    },
    onDeleteInvoiceConfirm() {
      this.deleteInvoice(this.toDelete);
      this.toDelete = null;
    },
    deleteInvoice(invoice_id) {
      axios
        .delete(`/invoice/${invoice_id}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('INVOICE.DELETED'));
          }
          else if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Betalning existerar på fakturan, kan ej radera');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_DELETE'));
        });
    },
    postStitchInvoices(option, invoice_ids, payment_status, created_from, created_to, printed, invoice_template_id, order_by) {
      return new Promise(async (resolve, reject) => {
        if (option === 'SELECTION' && (!invoice_ids || invoice_ids.length === 0)) {
          reject('invalid selection');
          return;
        }

        if (option === 'FILTER' && !payment_status) {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Betalstatus måste väljas');
          reject('invalid filter');
          return;
        }


        // stitch_invoice will only put multiple PDF files together
        axios
          .post('/invoice/stitch_invoices', {
            //invoice_ids: this.filteredItems.map(item => item.invoice_id),
            invoice_ids,
            payment_status,
            option: option,
            period_id: this.currentPeriodId,
            company_id: this.currentCompanyId,
            printed: printed,
            created_from,
            created_to,
            invoice_template_id,
            order_by: order_by,
          })
          .then(res => {
            if (res.status === 201) {
              resolve(res.data.job);
            }
            else {
              console.error('invalid status ' + res.status);
              reject('invalid status ' + res.status);
            }
          })
          .catch(err => {
            console.error(err);
            reject('error');
          });
      });

    },
    uploadBgMax() {
      let formData = new FormData();
      formData.append('file', this.file);
      // formData.append('file_id', this.form.file_id);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_BGMAX');
      // formData.append('form_file_id', this.form.file_id);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      axios
        .post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          axios
            .post('/invoice/bgmax', {
              file_id: res.data.fileobjs[0].file_id
            })
            .then(res => {
              if (res.status === 200) {
                this.toastr('success', this.$t('COMMON.OK'), 'BgMax-filen laddades upp');
                this.loadBgMax();
              } else {
                this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda bgMax-filen');
              }

            })
            .catch(err => {
              console.error(err);
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp bgMax-filen');
            });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda upp fil');
        });
    },
    submit_update(invoice_id, payment_status) {
      axios
        .put(`/invoice/${invoice_id}`, {
          payment_status
        })
        .then(
          res => {
            if (res.status === 200) {
              this.toastr('success', this.$t('COMMON.OK'), 'Fakturan uppdaterad');
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera fakturan');
            }
          },
          err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera fakturan');
          }
        )
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera fakturan');
        });
    },

  }
};
</script>
<style>
.v-window {
  overflow: visible !important;
}
</style>
