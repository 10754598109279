<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch">


    <div class="card-body pt-0 table-responsive" style="overflow: visible;">

      <b-row>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            id="input-group-created_from"
            :label="$t('PAGES.RENEWS.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            id="input-group-created_to"
            :label="$t('PAGES.RENEWS.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            label="Betalstatus"
          >
            <Multiselect
              v-model="filters.payment_status"
              id="status-filter"
              class="multiselect-blue mr-sm-2 mx-0"
              value-prop="code"
              object
              :close-on-select="false"
              :searchable="false"
              :options="payment_statuses"
            />
          </b-form-group>
        </b-col>
        <b-col xl="3" md="6" sm="12">
          <b-form-group
            label="Utskrivna"
          >
            <Multiselect
              v-model="filters.printed"
              id="status-filter"
              class="multiselect-blue mx-0"
              value-prop="code"
              object
              :close-on-select="false"
              :searchable="false"
              :options="printed_statuses"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col lg="6" sm="12">
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </b-col>
        <b-col lg="6" sm="12">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100">
            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 250]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              v-model="current_page"
              :total-rows="totalRows"
              :per-page="per_page"
              aria-controls="invoice-table"
              first-number
              last-number
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <b-table
        id="invoice-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :filter="filters"
        :items="ajax_pagination"
        :per-page="per_page"
        :current-page="current_page"
        head-variant="light"
        ref="invoiceTable"
        @filtered="onFiltered"
        @sort-changed="onSortChanged"
      >
        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            <a v-if="row.item.member" class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="show_member_clicked(row.item.member.member_id)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-user color-primary"></i>
              </span>
            </a>

            <a class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="plus_clicked(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/circle-plus-solid.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>

          </div>
        </template>

        <template #cell(selected)="row">
          <!--<v-simple-checkbox
            v-model="row.item.selected"
          ></v-simple-checkbox>-->
        </template>

        <template #row-details="row">
          <b-card style="cursor: default;" class="display-flex">
            <label for="update-status">Betalstatus:</label>
            <Multiselect
              v-model="paymentDropdowns[row.item.invoice_id]"
              id="update-status"
              class="multiselect-blue mt-2 mb-6"
              value-prop="code"
              object
              style="max-width: 150px"
              :close-on-select="false"
              :searchable="false"
              :options="payment_statuses"
              @change="value => updatePaymentStatus(row.item.invoice_id, value)"
            />
            <b-button size="sm" @click="submit_update(row.item.invoice_id)">Uppdatera status</b-button>
          </b-card>
        </template>
      </b-table>
      <b-row class="mt-8">
        <b-col><span>Antal rader: {{totalRows}}</span></b-col>
      </b-row>
      <!--end::Table-->
    </div>

    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import isBetween from 'dayjs/plugin/isBetween';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';

dayjs.extend(isBetween);

export default {
  name: 'invoice-table',
  mixins: [ toasts ],
  components: {
    Multiselect,
  },
  emits: ['createInvoiceClicked', 'print_invoice_clicked', 'download_invoice_clicked', 'downloadMultipleInvoicesClicked', 'email_sendout_clicked', 'create_pdf_clicked', 'downloadAllInvoicesClicked', 'selectInvoiceClicked', 'delete_invoice_clicked', 'submit_update', 'on_plus_clicked', 'on_match_bgmax_to_invoice', 'on_confirm_save', 'filters_changed'],
  computed: {
    ...mapGetters(['currentPeriodId', 'periods']),
    currentPeriod() {
      return this.periods.find(period => period.id === this.currentPeriodId);
    }
  },
  mounted() {

  },
  watch: {
    'filters.created_from'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.created_to'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    'filters.payment_status'() {
      const payment_status = this.filters.payment_status ? this.filters.payment_status.code : null;
      this.can_invoice = payment_status === 'NOT_PAID';
      this.$emit('filters_changed', { payment_status: payment_status, created_from: this.filters.created_from, created_to: this.filters.created_to });
    },
    currentPeriod() {

    }
  },
  methods: {

    show_member_clicked(member_id) {

    },

    plus_clicked(invoice) {
      this.$emit('on_plus_clicked', invoice);
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },


    get_options() {

      const options = {};

      if (this.filters.payment_status) {
        options.payment_status = this.filters.payment_status.code;
      }

      if (this.filters.printed) {
        options.printed = this.filters.printed.code;
      }

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      return options;
    },


    async get_invoice_ids() {

      try {
        const options = this.get_options();

        const response = await axios.post(`/invoice/invoice_ids`, {
          options: options
        });

        if (response.status === 200) {
          return response.data;
        }

        throw `invalid status ${err}`;
      }
      catch (err) {
        throw `get_invoice_ids error ${err}`;
      }

    },


    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['invoiceTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/invoice/pagination`, {
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          let i = 0;
          for (const a of arr) {
            a.index = i++;
            a.selected = this.copy_rows.findIndex(id => id === a.shop_order_id) >= 0;
          }

          this.copy_rows = arr;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/invoice/pagination/count?limit=${this.per_page}&page=${this.current_page}`, { options: options })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_GET_NUM_ORDERS'));
        });
    },

    gotoMemberClicked(member_id) {
      this.$router.push(`/ml-member-editor/${member_id}`)
    },
    onRowSelected(items) {
      this.selected = items;
    },

    getStatus(status) {
      return {
        PAID: this.$t('INVOICE.PAID'),
        NOT_PAID: this.$t('INVOICE.NOT_PAID'),
        PARTIALLY_PAID: this.$t('INVOICE.PARTIALLY_PAID')
      }[status];
    },
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },
    createInvoiceClicked() {
      this.$emit('createInvoiceClicked');
    },
    print_invoice_clicked(invoice_id) {
      this.$emit('print_invoice_clicked', invoice_id);
    },
    download_invoice_clicked(invoice_id) {
      this.$emit('download_invoice_clicked', invoice_id);
    },
    downloadMultipleInvoicesClicked() {
      this.$emit('downloadMultipleInvoicesClicked', this.selected);
      this.$refs.invoiceTable.clearSelected();
    },
    email_sendout_clicked() {
      this.$emit('email_sendout_clicked', this.get_options());
    },
    create_pdf_clicked() {
      this.$emit('create_pdf_clicked', this.get_options());
    },
    downloadAllInvoicesClicked() {
      this.$emit('downloadAllInvoicesClicked', this.filteredItems);
    },
    selectInvoiceClicked(invoice_id) {
      this.$emit('selectInvoiceClicked', invoice_id);
    },
    delete_invoice_clicked(invoice_id) {
      this.$emit('delete_invoice_clicked', invoice_id);
    },
    submit_update(invoice_id) {
      this.$emit('submit_update', invoice_id, this.paymentDropdowns[invoice_id].code);
    },
    updatePaymentStatus(invoice_id, value) {
      let newStatus = JSON.parse(JSON.stringify(this.paymentDropdowns));

      newStatus[invoice_id] = value;

      this.paymentDropdowns = newStatus;
    }
  },
  data() {
    return {
      totalRows: 0,
      orderBy: undefined,
      allSelected: false,
      can_invoice: false,
      current_page: 1,
      per_page: 100,
      options: [100, 150, 200],
      paymentDropdowns: {},
      payment_statuses: [
        { label: this.$t('INVOICE.PAID'), code: 'PAID' },
        { label: this.$t('INVOICE.NOT_PAID'), code: 'NOT_PAID' },
        { label: this.$t('INVOICE.PARTIALLY_PAID'), code: 'PARTIALLY_PAID' }
      ],
      printed_statuses: [
        { label: this.$t('INVOICE.PRINTED'), code: 1 },
        { label: this.$t('INVOICE.NOT_PRINTED'), code: 0 },
        { label: this.$t('COMMON.ALL'), code: null }
      ],
      filters: {
        created_from: null,
        created_to: null,
        dateFilterFrom: '',
        dateFilterTo: '',
        payment_status: '',
        printed: { label: this.$t('COMMON.ALL'), code: null },
      },
      filteredItems: [],
      selected: [],
      copy_rows: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          tdClass: 'td-short',
          thClass: 'td-short'
        },
        {
          key: 'invoice_id',
          label: '#',
          sortable: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'name',
          label: this.$t('INVOICE.RECIPIENT'),
          sortable: false,
          formatter: (_, __, item) => {
            if (item.member && item.member.invoice_firstname && item.member.invoice_lastname) {
              return `(M) ${item.member.invoice_firstname} ${item.member.invoice_lastname}`;
            } else if (item.member && item.member.firstname && item.member.lastname) {
              return `(M) ${item.member.firstname} ${item.member.lastname}`;
            } else if (item.receiving_company) {
              return `(F) ${item.receiving_company.name}`
            }
            return '';
          },
          sortByFormatted: true,
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          key: 'total_sek',
          label: this.$t('INVOICE.TOTAL_SEK'),
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'ocr',
          label: 'OCR',
          sortable: true,
          tdClass: 'td-short3',
          thClass: 'td-short3'
        },
        {
          key: 'printed',
          label: this.$t('INVOICE.PRINTED'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.printed ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          },
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'paid_at',
          label: this.$t('INVOICE.PAID_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return item.paid_at ? dayjs(item.paid_at).format('YYYY-MM-DD') : '';
          },
          sortByFormatted: true,
          tdClass: 'td-short5',
          thClass: 'td-short5'
        },
        {
          key: 'status',
          label: this.$t('INVOICE.STATUS'),
          sortable: true,
          formatter: (_, __, item) => {
            return this.getStatus(item.payment_status);
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
      ]
    };
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 25%;
}

:deep .td-short {
  width: 32px;
}

:deep .td-short2 {
  width: 56px;
}

:deep .td-short3 {
  width: 76px;
}

:deep .td-short4 {
  width: 96px;
}

:deep .td-short5 {
  width: 116px;
}


:deep td {
  vertical-align: middle !important;
}

@media screen and (max-width: 768px) {
  #multiple-invoices-btn {
    margin-bottom: 2rem;
  }

  .date-time-picker {
    margin: 0;
  }

  .filter-box {
    margin-top: 1rem;
    padding: 0 !important;
  }
}
#status-filter {
  width: 200px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}

.multiselect-blue {
  --ms-option-bg-selected: #3699FF;
  --ms-option-bg-selected-pointed: #3699FF;
}
</style>
